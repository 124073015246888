<template>
  <div class="label-list">
    <el-form :inline="true" class="form-content">
      <el-form-item label="任务主题">
        <el-input
          placeholder="请输入关键字查询"
          v-model="title"
          class="input-with-select"
          style="width: 400px"
        >
          <el-button
            slot="append"
            @click="getList"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="检索状态">
        <el-select
          placeholder="请选择检索状态"
          v-model="status"
          clearable
          style="width: 200px"
        >
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="创建时间">
        <el-date-picker
          v-model="dateRangeTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" class="ml20" @click="getList">搜索</el-button>
        <el-button class="ml20" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="search-list">
      <el-button type="primary" @click="add">添加</el-button>
      <el-button
        type="danger"
        @click="deleteHandle"
        :disabled="dataListSelections.length ? false : true"
        >删除</el-button
      >
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="title" label="检索任务"></el-table-column>
        <el-table-column prop="labelTypeName" label="检索状态"
          ><template slot-scope="scope">
            <span v-if="scope.row.status == 1">待检索</span>
            <span v-if="scope.row.status == 3">检索中</span>
            <span v-if="scope.row.status == 4">检索完成</span>
          </template></el-table-column
        >
        <el-table-column prop="createTime" label="最近检索时间">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.mbglRetrieveRecords &&
                scope.row.mbglRetrieveRecords.length
              "
              >{{
                scope.row.mbglRetrieveRecords[0].createTime
                  ? scope.row.mbglRetrieveRecords[0].createTime
                  : "-"
              }}</span
            >
            <span v-else>-</span>
          </template></el-table-column
        >
        <el-table-column prop="createName" label="检索进度"></el-table-column>
        <el-table-column prop="updateTime" label="数据下载">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 4"
              type="text"
              @click="downloadData(scope.row)"
              >下载</el-button
            >
            <el-button
              type="text"
              @click="addQuestionSearch(scope.row)"
              v-if="scope.row.status == 1 || scope.row.status == 4"
              >重新检索</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="right" align="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="add(scope.row)"
              v-if="scope.row.status == 1"
              >编辑</el-button
            >
            <el-button type="text" @click="handleInfo(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <el-dialog title="检索结果下载" :visible.sync="visible2" width="800px">
      <el-table
        :data="dataList2"
        style="width: 100%"
        v-loading="dataListLoading2"
      >
        <el-table-column prop="createTime" label="检索时间"></el-table-column>
        <el-table-column label="检索状态">
          <template slot-scope="scope">
            <span v-if="scope.row.url">检索完成</span>
            <span v-else>检索中</span>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="下载次数">
          <template slot-scope="scope">{{
            scope.row.number ? scope.row.number : 0
          }}</template>
        </el-table-column>
        <el-table-column label="操作" header-align="right" align="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="downloadUrl(scope.row)"
              v-if="scope.row.url"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle2"
        @current-change="currentChangeHandle2"
        :current-page="pageNo2"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize2"
        :total="totalPage2"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-dialog>
    <el-dialog title="新增/编辑任务" :visible.sync="visible" width="800px">
      <el-form
        :model="form"
        label-width="100px"
        :rules="dataRules"
        ref="form"
        class="dialog-form-con"
      >
        <el-form-item label="任务主题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入任务主题"
            style="width: 350px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Qs from "qs";
export default {
  name: "label",
  data() {
    return {
      title: "",
      status: "",
      dataList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      dateRangeTime: [],
      teamsManage: [],
      typeList: [
        { name: "待检索", value: 1 },
        { name: "检索中", value: 3 },
        { name: "检索完成", value: 4 },
      ],
      visible: false,
      form: {
        title: "",
      },
      dataRules: {
        title: [{ required: true, message: "请输入任务主题", trigger: "blur" }],
      },
      edit: false,
      flag: true, //防止重复点击
      visible2: false,
      dataList2: [],
      dataListLoading2: true, //列表loading
      dataListSelections2: [], //选中的列
      pageNo2: 1,
      pageSize2: 10,
      totalPage2: 0, //总页数
      retrievalId: "",
    };
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList() {
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {
        teamsId: teamsId,
        status: this.status,
        title: this.title,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };
      if (this.dateRangeTime && this.dateRangeTime.length) {
        params.startTime = this.dateRangeTime[0];
        params.endTime = this.dateRangeTime[1];
      }
      const { data: res } = await this.$httpAes({

        url: this.$http.adornUrl("/mbglLabelIndex/list"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.dataList = res.data;
        this.totalPage = res.totalCount;
      }
      this.dataListLoading = false;
    },
    add(item) {
      if (item && item.id) {
        // this.form = JSON.parse(JSON.stringify(item));
        this.$router.push({
          name: "addCaseSearch",
          query: { id: item.id },
        });
      } else {
        this.visible = true;
        this.$nextTick(() => {
          this.$refs["form"].resetFields();
          this.form = {
            title: "",
          };
          if (localStorage.currentTeam) {
            this.form.teamsId = JSON.parse(localStorage.currentTeam).id;
            this.form.enertyId = JSON.parse(localStorage.currentTeam).entityId;
          }
        });
      }
      // if (item.id) {
      //   this.$router.push({
      //     name: "addCaseSearch",
      //     query: { id: item.id },
      //   });
      // } else {
      //   this.$router.push({
      //     name: "addCaseSearch",
      //   });
      // }
    },
    cancel() {
      this.$refs["form"].resetFields();
      this.visible = false;
      this.form = {
        title: "",
      };
    },
    async submit() {
      let params = {
        title: this.form.title,
        // status: 1,
      };
      if (localStorage.currentTeam) {
        params.teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      if (this.form.id) {
        params.id = this.form.id;
      }
      // console.log(params);
      this.$refs.form.validate(async (valid) => {
        if (this.flag) {
          this.flag = false;
          if (valid) {
            const { data: res } = await this.$httpAes({
              url: this.$http.adornUrl(
                this.form.id ? "/mbglLabelIndex/update" : "/mbglLabelIndex/add"
              ),
              method: "post",
              data: params,
            });
            if (res.status) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.flag = true;
                  this.visible = false;
                  this.getList();
                  if (res.data && res.data) {
                    this.$router.push({
                      name: "addCaseSearch",
                      query: { id: res.data },
                    });
                  }
                },
              });
            } else {
              this.$message.error(res.msg);
              this.flag = true;
            }
          } else {
            return false;
          }
        }
      });
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除分组]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          let str = "";
          if (ids.length) {
            str = ids.join(",");
          }
          let params = { stringParam1: str, intParam1: 2 };
          const id = ids.join(`,`);
          const { data: res } = await this.$httpAes({
            url: this.$http.adornUrl("/mbglLabelIndex/updateStatusByIds"),
            method: "post",
            data: params,
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getList();
              },
            });
          }
        })
        .catch(() => {});
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/dict/list"),
        method: "post",
        data: {
          stringParam1: "标签类型",
        },
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    reset() {
      this.labelName = "";
      this.labelType = "";
      this.dateRangeTime = [];
      this.isGrouping = "";
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 显示下载列表
    downloadData(row) {
      this.visible2 = true;
      this.retrievalId = row.id;
      this.getDataList();
    },
    // 下载内容
    async getDataList() {
      let params = {
        intParam1: this.pageSize2,
        intParam2: this.pageNo2,
        stringParam1: this.retrievalId,
      };
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/mbglRetrieveRecords/list"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.dataList2 = res.data;
        this.totalPage2 = res.totalCount;
      }
      this.dataListLoading2 = false;
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getDataList();
    },
    // 添加检索
    addQuestionSearch(row) {
      let params = { retrievalId: row.id };
      this.$httpAes({
        url: this.$http.adornUrl("/mbglRetrieveRecords/add"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.updateStatus(row.id);
        }
      });
    },
    updateStatus(id) {
      let params = { stringParam1: id, intParam1: 3 };
      this.$httpAes({
        url: this.$http.adornUrl("/mbglLabelIndex/updateStatusByIds"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.$message({
            message: "重新检索成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.$router.push({
                name: "caseSearch",
              });
            },
          });
        }
      });
    },
    // 下载
    downloadUrl(row) {
      var that = this;
      that.updateNum(row);
    },
    // 进入详情页面
    handleInfo(row) {
      this.$router.push({
        name: "caseSearchInfo",
        query: { id: row.id },
      });
    },
    // 更新下载次数
    updateNum(row) {
      // 添加检索
      let params = { id: row.id };
      if (row.number) {
        params.number = row.number + 1;
      } else {
        params.number = 1;
      }
      this.$httpAes({
        url: this.$http.adornUrl("/mbglRetrieveRecords/update"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          let link = document.createElement("a");
          let url = "";
          if (row.url) {
            url = "https://" + row.url.substr(13);
          }
          link.href = url;
          link.click();
          this.getDataList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
